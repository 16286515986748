@font-face {
  font-family: 'CircularStd-Book';
  src: url("https://monthlywrapped-fonts.s3.amazonaws.com/CircularStd-Book.woff") format('woff'),
  url("https://monthlywrapped-fonts.s3.amazonaws.com/CircularStd-Book.woff2") format('woff2'),
  url("https://monthlywrapped-fonts.s3.amazonaws.com/CircularStd-Book.ttf") format('truetype');
}

@font-face {
  font-family: 'CircularStd-Bold';
  src: url("https://monthlywrapped-fonts.s3.amazonaws.com/CircularStd-Bold.woff") format('woff'),
  url("https://monthlywrapped-fonts.s3.amazonaws.com/CircularStd-Bold.woff2") format('woff2'),
  url("https://monthlywrapped-fonts.s3.amazonaws.com/CircularStd-Bold.ttf") format('truetype'); 
}

body {
  margin: 0;
  font-family: 'CircularStd-Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-images {
  width: 400;
}

