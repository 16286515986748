.landing-page{
  margin-left: 100px;
  margin-right: 100px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 50px;
  font-family: CircularStd-Bold;
}

.sub-heading{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: #6A6767;
  font-size: 30px;
  font-family: CircularStd-Book;
}

span{
  display: contents;
  color: #5FB536
}

.photo-list{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0;
}

.photo-list li {
  list-style: None;
  padding: 0;
  margin: 0 20px;
  margin-top: 15px;
  box-shadow: 0 8px 6px -6px rgb(129, 129, 129);
}

img{
  width: 300px;
  border-radius: 4px;
}

.btn-success {
  /* display: block; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  min-height: 60px;
  min-width: 300px;
  font-size: 20px;
  font-family: CircularStd-Bold;
  background-color: #4475C2 !important;
  border: 0 !important;
}

.btn-success:hover {
  background: #6b98df !important;
}

.login-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

/* navbar */

nav{
  /* display: flex; */
  /* justify-content: flex-end; */
  /* align-items: center; */
  color: black;
  min-height: 20vh;
  padding: 15px;
}

.nav-links{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}

.nav-links li{
  font-family: CircularStd-Bold;
  font-size: 25px;
  color: #313131;
  transition: all 50ms ease-in;
}

.nav-links li:hover {
  color: #535353;
}

.nav-img{
  margin-right: auto;
  margin-left: -20px;
}

/* footer */
.footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 20vh;
  color: black;
  padding: 30px;
}

/* stats image grid */
.image-grid{
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.grid-image {
  display: block;
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
} 

.image-grid-header {
  font-family: CircularStd-Bold;
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

/* stats page menu */
.menu {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 40px;
  text-align: center;
}

.menu-btn {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  min-height: 50px;
  min-width: 150px;
  background: transparent !important;
  color:#5FB536;
  border-radius: 5px;
  border: 2px solid #5FB536 !important;
}

.menu-btn:hover {
  color: #5FB536 !important;
  background-color: #e0f3d7 !important;
}

.menu-btn:focus {
  color: #5FB536;
  background-color: none;
}

.menu-header {
  font-family: CircularStd-Bold;
  padding-bottom: 20px;
}

/* register page  */

.register-page {
  margin-left: 100px;
  margin-right: 100px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.register-text {
  /* text-align: center; */
  font-size: 30px;
}

@media screen and (max-width: 600px) {

  .landing-page {
    margin-left: 20px;
    margin-right: 20px;
  }

  .heading {
    font-size: 30px;
  }
  .sub-heading {
    font-size: 15px;
  }

  .nav-links li{
    font-size: 18px;
  }

  .nav-img {
    width: 150px;
  }

  .menu-header {
    font-size: 30px;
  }

  .menu-btn {
    min-height: 40px;
    min-width: 100px;
  }

  .image-grid-header {
    font-size: 25px;
    font-size: 100px;
  }

}

@import 'bootstrap/dist/css/bootstrap.min.css';